import useBasicApi from '@/api/useBasicApi'
import idEndpointGetter from '@/api/idEndpointGetter'
import config from '@/config'

const axios = config.urls.AXIOS_API

export default function packageVersionsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/package_versions`

  return idEndpointGetter({
    ENDPOINT,
    for: packageType => {
      const PACKAGE_TYPE_ENDPOINT = `${ENDPOINT}/${packageType}`
      return {
        ENDPOINT: PACKAGE_TYPE_ENDPOINT,
        ...useBasicApi(PACKAGE_TYPE_ENDPOINT, ['get']),
        changeDefaultVer: async version =>
          axios.put(`${PACKAGE_TYPE_ENDPOINT}/`, { default_version: version }),
      }
    },
  })
}
